import React from 'react'
import AnimatedLink from '../global/animatedLink';
import animateScrollTo from 'animated-scroll-to';
import { handleMenuOpen } from './../../state/actions/actions-site';
import { useSelector, useDispatch } from 'react-redux';
import { useStaticQuery, graphql } from "gatsby"

export default function Nav(props) {
  const { extraClass, home } = props;
  const dispatch = useDispatch();
  const menuOpen = useSelector(state => state.siteState.menuOpen);
  const data = navData();

  function handleHIW(e) {
    e.preventDefault();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const offset = document.querySelector('.homepage-explainer').getBoundingClientRect().top + scrollTop;
    // When top nav hides while scrolling down, sets the correct offset
    animateScrollTo(offset, {speed: 1500})
    linkOutClick()
  }

  function linkOutClick() {
    setTimeout(() => {
      document.querySelector('.tl-edges').classList.remove("nav-open");
      dispatch(handleMenuOpen(false))
    }, 100);
  }

  function handleBugerClick() {
    if (menuOpen) {
      document.querySelector('.tl-edges').classList.remove("nav-open");
      dispatch(handleMenuOpen(false))
      setTimeout(() => document.querySelector('.nav-burger').focus(), 300);
    } else {
      document.querySelector('.tl-edges').classList.add("nav-open");
      dispatch(handleMenuOpen(true))
      setTimeout(() => document.querySelector('.nav-burger').focus(), 300);
    }
  }

  return (
    <nav className={`nav ${extraClass ? extraClass : ''}`}>
      <div className="outer-container">        
        <AnimatedLink 
          to={'/'} 
          delay={0}
          logEvent={`Nav - Helpful Chat logo click`}
        >
          <img src={data.logo.url} alt="Helpful Chat Logo" className={`nav__logo`}/>
        </AnimatedLink>
        <div className="nav__menu">
          <AnimatedLink 
            className={`nav__menu-item`} 
            to={'/chatroom'} 
            delay={0}
            logEvent={`Nav - Start chatting link`}
          >
            Start Chatting
          </AnimatedLink>
          {data.nav.map((nav, index) => {
            return (
              <AnimatedLink 
                className={`nav__menu-item`} 
                to={nav.linkUrl} 
                key={index} 
                delay={0}
                logEvent={`Nav - ${nav.linkTitle} click`}
              >
                {nav.linkTitle}
              </AnimatedLink>
            )
          })}
        </div>
        <button 
          className={menuOpen ? "nav-burger active" : "nav-burger"}
          tabIndex={menuOpen  ? 0 : 1}
          aria-expanded={menuOpen ? 'true' : 'false'}
          aria-label={menuOpen ? "button - close navigation menu" : "button - open navigation menu"} 
          role="button" 
          aria-controls="navigation"
          onClick={handleBugerClick}
        >
          <div className="nav-burger__bar-1"></div>
          <div className="nav-burger__bar-2"></div>
          <div className="nav-burger__bar-3"></div>
          <div className="nav-burger__text">{menuOpen ? `Close` : `Menu`}</div>
        </button>
        <div className={menuOpen ? "nav-mobile active" : "nav-mobile"}>
          <AnimatedLink 
            className={`nav-mobile-item`} 
            to={'/'} 
            onClick={linkOutClick} 
            delay={0}
            logEvent={`Nav mobile - Helpful Chat logo click`}
          >
            Home
          </AnimatedLink>
          <AnimatedLink 
            className={`nav-mobile-item`} 
            to={'/chatroom'} 
            onClick={linkOutClick} 
            delay={0}
            logEvent={`Nav mobile - Start chatting link`}
          >
            Start Chatting
          </AnimatedLink>
          {home !== undefined ? 
            <a className={`nav-mobile-item`} onClick={handleHIW}>
              How it works
            </a>
          :
            <AnimatedLink 
              className={`nav-mobile-item`} 
              to={'/?scrollto'}
              logEvent={`Nav mobile - How it works link`}
            >
              How it works
            </AnimatedLink>
          }
          {data.nav.map((nav, index) => {
            return (
              <AnimatedLink
                className={`nav-mobile-item`}
                to={nav.linkUrl}
                key={index}
                onClick={linkOutClick}
                delay={0}
                logEvent={`Nav mobile - ${nav.linkTitle} link`}
              >
                {nav.linkTitle}
              </AnimatedLink>
            )
          })}
          <AnimatedLink
            className={`nav-mobile-item`}
            to={'/privacy-policy'}
            onClick={linkOutClick}
            delay={0}
            logEvent={`Nav mobile - Privacy policy link`}
          >
            Privacy Policy
          </AnimatedLink>
          <AnimatedLink
            className={`nav-mobile-item`}
            to={'/terms-and-conditions'}
            onClick={linkOutClick}
            delay={0}
            logEvent={`Nav mobile - Terms and conditions link`}
          >
            Terms & Conditions
          </AnimatedLink>
        </div>
      </div>
    </nav>
  )
}

const navData = () => {  
  const { data } = useStaticQuery(graphql`
    query {
      data: datoCmsNav {
        logo {
          url
        }
        nav {
          linkTitle
          linkUrl
        }
      }
    }
  `)
  return data
}
import React, { useEffect } from 'react'
import { domToReact } from 'html-react-parser';
import parse from 'html-react-parser';
import AnimatedLink from '../components/global/animatedLink';
import filterObject from 'filter-obj';
import ReactDOMServer from 'react-dom/server';

export function parseHTML (html) {
  // First pass cleans HTML
  let firstParse = ReactDOMServer.renderToStaticMarkup(parse(html, replaceHTML));
  // Add special anchor checking for second pass
  let wrappedAnchors = WrapRelativeAnchors(firstParse);
  // Second parse
  return parse(wrappedAnchors, replaceHTML)
}

const replaceHTML = {
  replace: domNode => {
    if (domNode.attribs) {

      // Clear the HTML of a lot of extra garbage styles that get added in the CMS
      let acceptableAttributes = ["class", "id", "width", "height", "href", "start", 'src', 'Source'];
      const newAttrs = filterObject(domNode.attribs, key => acceptableAttributes.includes(key));
      domNode.attribs = newAttrs;

      switch (domNode.name) {
        case 'a':
          // Relative links converter
          // Because we're doing mulitple passes - AnimatedLink gets confused
          // Before second parse wrappedAnchors adds this special attr to trigger this conversion
          if (domNode.attribs.secondParseAnchor) {
            return (
              <AnimatedLink to={domNode.attribs.href} className={`internal-link`}>
                {domNode.children[0].data}
              </AnimatedLink>
            )
          }
          break;

        case 'iframe':
          // let youtubeID = YouTubeGetID(domNode.attribs.src);
          // let thumbnail = `https://img.youtube.com/vi/${youtubeID}/0.jpg`;
          let iframeWrap = React.createElement('div', 
            {className: 'video-container'}, 
            React.createElement('iframe', domNode.attribs)
          )
          return iframeWrap

        case 'table':
          return (
            <div className="table-wrap">
              <table>
                {domToReact(domNode.children)}
              </table>
            </div>
          )

        case 'img':
          let src = '';
          if (!domNode.attribs.src.includes('?fit=clip&w=813')) {
            src = domNode.attribs.src + '?fit=clip&w=813';
          } else {
            src = domNode.attribs.src;
          }
          return React.createElement(
            'img', 
            {
              className:'content-image',
              src: src,
              width: domNode.attribs.width,
              height: domNode.attribs.height
            } 
          );

        case 'script':
          return
      
        default:
          return domNode;
      }
    }
  }
};

const WrapRelativeAnchors = (html) => {
  const anchorRegex = /<a[\s]+([^>]+)>((?:.(?!\<\/a\>))*.)<\/a>/g;
  return html.replace(anchorRegex, function replace(match) { 
    let splitAnchor = match.split('href');
    return splitAnchor[0] + ' secondParseAnchor href' + splitAnchor[1]; 
  });
}